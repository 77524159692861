import React from "react";

const Navigation = () => {
  const navigation = {
    msc: [
      // { name: "Register", href: "registration" },
      { name: "About", href: "about" },
      { name: "Contact Us", href: "contact" },
      {
        name: "Feedback",
        href: "https://docs.google.com/forms/d/e/1FAIpQLSe8bWwIHfbypGwS4CTwswaLP8sNTkN8ZB3bolfoY2Qc3s7h7Q/viewform",
      },
    ],
    govt: [
      {
        name: "Ministry of Youth Affairs & Sports",
        href: "https://yas.gov.in/",
      },
      { name: "Mizoram Government", href: "https://mizoram.nic.in/" },
      {
        name: "Mizoram Sports & Youth Services",
        href: "https://sys.mizoram.gov.in/",
      },
      {
        name: "Mizoram State Sports Council",
        href: "https://sportscouncil.mizoram.gov.in/",
      },
    ],
  };

  return (
    <footer className="mx-auto mt-40 z-20 max-w-7xl overflow-hidden px-6 pb-20 sm:mt-64 sm:pb-24 lg:px-8">
      <div className="flex flex-col justify-between sm:flex-row">
        <div className="flex place-items-center justify-center">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3652.4742233197085!2d92.73534357525666!3d23.730462878683987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x374d959315d117bb%3A0xc84de6f0b8e4ceff!2sBaptist%20Centre%2CFalkland!5e0!3m2!1sen!2sin!4v1717173400134!5m2!1sen!2sin"
            width="400"
            height="300"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          />
        </div>
        <div className="mt-10 mr-20 md:mt-0">
          <h3 className="text-sm font-semibold leading-6 text-gray-900">MSC</h3>
          <ul role="list" className="mt-6 space-y-4">
            {navigation.msc.map((item) => (
              <li key={item.name}>
                <a
                  href={item.href}
                  className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                >
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
        {/* Similar mapping for navigation.govt */}
        <div className="mt-10 md:mt-0">
          <h3 className="text-sm font-semibold leading-6 text-gray-900">
            Government
          </h3>
          <ul role="list" className="mt-6 space-y-4">
            {navigation.govt.map((item) => (
              <li key={item.name}>
                <a
                  href={item.href}
                  className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                >
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
        {/* Image and social media icons */}
        <div className="mt-5">
          <img src="m1.png" className="h-72" alt="Logo" />
        </div>
      </div>
      <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 md:flex md:items-center md:justify-between lg:mt-24">
        <div className=" flex flex-row justify-center">
          <a
            href="https://www.facebook.com/p/Mizoram-State-Sports-Council-100064404563021/"
            className=""
          >
            <i
              className="pi pi-facebook"
              style={{ fontSize: "1.5rem", color: "#1877F2" }}
            />
          </a>
          <a
            href="https://www.youtube.com/@mizoramstatesportscouncil9271"
            className=" ml-5"
          >
            <i
              className="pi pi-youtube"
              style={{ fontSize: "1.5rem", color: "#FF0000" }}
            />
          </a>
          <a
            href="https://www.instagram.com/mizoramstatesportscouncil/"
            className=" ml-5"
          >
            <i
              className="pi pi-instagram"
              style={{ fontSize: "1.5rem", color: "#fa7e1e" }}
            />
          </a>
        </div>
        <p className="mt-8 text-xs leading-5 text-gray-500 md:order-1 md:mt-0">
          &copy; {new Date().getFullYear()} Mizoram State Sports Council. All
          rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Navigation;
