import React from "react";
import TopNav from "../components/TopNav";
import Bottom from "../components/Bottom";

export default function Landing() {
  return (
    <div className="">
      <header className=" sticky">
        <TopNav />
      </header>
      <div className="bg-white">
        <div className="relative">
          <div className="mx-auto max-w-7xl">
            <div className="relative z-10 pt-14 lg:w-full lg:max-w-2xl">
              <svg
                className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                aria-hidden="true"
              >
                <polygon points="0,0 90,0 50,100 0,100" />
              </svg>

              <div className="relative px-6 py-10 sm:py-40 lg:px-8 lg:py-56 lg:pr-0">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                  <div className="hidden sm:mb-10 sm:flex">
                    <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-500 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                      {/* Commented out link for demonstration, uncomment and adjust as needed */}
                      {/* <a
                      href="#"
                      className="whitespace-nowrap font-semibold text-indigo-600"
                      aria-hidden="true"
                    >
                      Read more <span aria-hidden="true">&rarr;</span>
                    </a> */}
                    </div>
                  </div>
                  <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                    Mizoram Sports Conclave 2024
                  </h1>
                  <p className="text-xl">Aizawl | 13 - 14 June, 2024</p>
                  <p className="pt-20 sm:pt-28 text-2xl leading-8 text-gray-600">
                    Paving the way towards Vision 2036
                  </p>
                  <div className="mt-10 flex items-center gap-x-6">
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSe8bWwIHfbypGwS4CTwswaLP8sNTkN8ZB3bolfoY2Qc3s7h7Q/viewform"
                      style={{ backgroundColor: "#0094d3" }}
                      className="rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Feedback
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              <img
                className="aspect-[2/3] object-cover lg:aspect-auto lg:h-full lg:w-full sm:h-full"
                src="1.webp"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="px-6 py-20 sm:px-6 sm:py-20 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Mizoram Sports Conclave
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
              The Mizoram Sports Conclave 2024 (MSC 2024) is an initiative under
              ‘Vision 2036’ by the Mizoram State Sports Council, aiming to
              catapult into action, Mizoram’s ambition for its athletes to
              participate and represent India in the 2036 Olympics. The conclave
              will mark an important stepping stone for Mizoram in its
              transformative journey towards being a center of sports
              excellence.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="about"
                className="text-sm font-semibold leading-6 text-gray-900 hover:text-[#0094d3]"
              >
                Read more <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex place-items-center justify-center">
        <div className="relative isolate overflow-hidden w-10/12 rounded-lg bg-gray-900">
          <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Towards Excellence
              </h2>
              <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
                Join in nurturing Olympic dreams
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSe8bWwIHfbypGwS4CTwswaLP8sNTkN8ZB3bolfoY2Qc3s7h7Q/viewform"
                  className="rounded-md w-60 bg-white px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                >
                  Feedback
                </a>
                <a
                  href="contact"
                  className="rounded-md w-60 bg-white px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Bottom />
    </div>
  );
}
