import About from './screens/About';
import Contact from './screens/Contact';
import Landing from './screens/Landing';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";


function App() {
  return (
      <>
          <Router>
              <Routes>
                  <Route
                      exact
                      path="/"
                      element={<Landing />}
                  />
                                    <Route
                      exact
                      path="/about"
                      element={<About />}
                  />
                                    <Route
                      exact
                      path="/contact"
                      element={<Contact />}
                  />
              </Routes>
          </Router>
      </>
  );
}

export default App;