import React from "react";
import TopNav from "../components/TopNav";
import Bottom from "../components/Bottom";

const Contact = () => {
  return (
    <div className="h-svh ">
      <TopNav />
      <div className=" flex flex-col sm:flex-row place-content-center">
        <div className=" border-opacity-5 mt-20 flex flex-col w-full sm:w-2/4 h-96 place-content-center border-gray-500 border-2">
          <div className=" ml-10">
            <div className=" font-semibold text-2xl">Get In Touch</div>
            <div className=" mt-7">We are happy to hear from you.</div>
            <a
              href="tel:+91 7042819941"
              className="flex flex-row place-items-center mt-7 hover:text-[#0094d3]"
            >
              <i className="pi pi-phone" style={{ fontSize: "1rem" }} />
              <div className=" pl-2 text-sm ">7042819941</div>
            </a>
            <a
              href="https://wa.me/7042819941"
              className="flex flex-row place-items-center mt-3  hover:text-[#0094d3]"
            >
              <i className="pi pi-whatsapp" style={{ fontSize: "1rem" }} />
              <div className=" pl-2 text-sm">7042819941</div>
            </a>
            <a
              href="mailto:admin@mizoramsportsconclave.com"
              className="flex flex-row place-items-center mt-3  hover:text-[#0094d3]"
            >
              <i className="pi pi-envelope" style={{ fontSize: "1rem" }} />
              <div className=" pl-2 text-sm">
                admin@mizoramsportsconclave.com
              </div>
            </a>
          </div>
        </div>
        <div className=" border-opacity-5 mt-0 sm:mt-20 flex flex-col w-full sm:w-2/4 h-96 justify-center place-items-center border-gray-500 border-2">
          <img src="logo1.webp" className=" h-52 w-52" />
        </div>
      </div>
      <Bottom />
    </div>
  );
};

export default Contact;
