import React, { useState } from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/outline";

import "primeicons/primeicons.css";

// Assuming these are imported from somewhere appropriate in your project
import { Dialog, DialogPanel } from "@headlessui/react"; // Adjust the import path as necessary

const Navigation = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const navigation = [
    { name: "Home", href: "/" },
    { name: "MSSC", href: "https://sportscouncil.mizoram.gov.in/" },
    {
      name: "Feedback",
      href: "https://docs.google.com/forms/d/e/1FAIpQLSe8bWwIHfbypGwS4CTwswaLP8sNTkN8ZB3bolfoY2Qc3s7h7Q/viewform",
    },
    { name: "About MSC", href: "about" },
    // { name: "Gallery", href: "gallery" },
    { name: "Contact Us", href: "contact" },
  ];

  return (
    <div className="">
      {/* Logo and other elements */}
      <div class="flex flex-row items-center justify-between bg-white py-5 h-28">
        <a href="/">
          <img src="logo1.webp" className="h-24 w-auto ml-4 sm:ml-10" />
        </a>
        {/* Other logos */}
        <a href="https://yas.gov.in/">
          <img src="gov.png" className="h-24 w-auto" />
        </a>
        <a href="https://sportscouncil.mizoram.gov.in/">
          <img src="mssclogo.png" className="h-24 w-auto mr-4 sm:mr-10" />
        </a>
      </div>
      <header class="top-0 z-50 sticky bg-white">
        {/* Mobile Menu Button */}
        <nav
          style={{ backgroundColor: "#0094d3" }}
          className="mx-auto flex h-20 w-full items-center justify-between lg:px-8 overflow-hidden"
          ariaLabel="Global"
        >
          <div className="h-24 sm:h-20 bg-white w-32 flex place-items-center justify-center">
            <a href="/">
              <img className="h-14 w-auto" src="logo.webp" alt="" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md text-white mr-5"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <i className="pi pi-bars" style={{ fontSize: "2rem" }} />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-md font-bold leading-6 text-white"
              >
                {item.name}
              </a>
            ))}
          </div>
          {/* Additional elements like Register link can be added here if needed */}
        </nav>

        {/* Mobile Dialog */}
        <Dialog open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            {/* Dialog content */}
            <div className="">
              <a href="/" className="-m-1.5 p-1.5">
                <img className="h-16 w-auto" src="logo.webp" alt="" />
              </a>
              <button
                onClick={() => setMobileMenuOpen(false)}
                className="rounded-md text-gray-700"
              ></button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>
    </div>
  );
};

export default Navigation;
