import React from "react";
import Nav from "../components/TopNav";
import Footer from "../components/Bottom";
const About = () => {
  return (
    <div className="bg-white">
      <Nav />
      <main className="isolate">
        {/* Hero section */}
        <div className="relative isolate -z-10 overflow-hidden bg-gradient-to-b from-indigo-100/20">
          <div className="mx-auto max-w-7xl px-6 py-20 sm:py-20 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
              <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">
                Mizoram Sports Conclave
              </h1>
              <img
                src="MSC Mizo Olympian.webp"
                alt=""
                className="h-full mt-5 w-full max-w-lg rounded-2xl object-cover lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2"
              />
              <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                <p className="text-lg leading-8 text-gray-600">
                  The Mizoram Sports Conclave 2024 (MSC 2024) is an initiative
                  under ‘Vision 2036’ by the Mizoram State Sports Council,
                  aiming to catapult into action, Mizoram’s ambition for its
                  athletes to participate and represent India in the 2036
                  Olympics. The conclave will mark an important stepping stone
                  for Mizoram in its transformative journey towards being a
                  center of sports excellence.
                </p>
                <p className="text-lg mt-5 leading-8 text-gray-600">
                  Featuring industry leaders, athletes, and experts, MSC will
                  serve as a platform for key stakeholders to voice their
                  opinions and share expertise, thereby inspiring the
                  development of world-class facilities and promote the growth
                  of future athletes in the state through lively and meaningful
                  discourse.
                </p>
                <p className="text-lg mt-5 leading-8 text-gray-600">
                  By 2036, Mizoram will be known for its robust sports
                  ecosystem, producing Olympic-level athletes. The combined
                  efforts of the government, sports associations, educational
                  institutions, and the community will make Mizoram a beacon of
                  sports excellence and Olympic success.
                </p>
              </div>
              <img
                src="MSC Mizo Olympian.webp"
                alt=""
                className="h-full w-full max-w-lg rounded-2xl object-cover lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 hidden lg:hidden xl:hidden"
              />
            </div>
          </div>
          <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
        </div>
        {/* Additional sections like Timeline, Logo cloud, Content, Stats, and Footer would go here */}
      </main>
      <Footer />
    </div>
  );
};

export default About;
